import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { KeycloakProfile } from 'keycloak-js';

export interface AuthState {
    isAuthenticated: boolean;
    profile: KeycloakProfile | null;
}

export const AuthStore = signalStore(
    withState<AuthState>({
        isAuthenticated: false,
        profile: {},
    }),
    withMethods((store) => ({
        authenticated: (profile: KeycloakProfile) => {
            patchState(store, { isAuthenticated: true, profile });
        },
        loggedout: () => {
            patchState(store, { isAuthenticated: false, profile: null });
        },
    }))
);