import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@codingbook/ngx-env';
import { GetGroupsDTO, GetPendingRequestsDTO, Group } from '@codingbook/shared';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {

  environment: Environment;
  readonly BASE_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) environment: Environment 
  ) {
    this.environment = environment;
    this.BASE_URL = `${this.environment.SOCIAL_URL}/groups`;
  }

  getAll() {
    return this.http.get<GetGroupsDTO>(`${this.BASE_URL}`);
  }

  create(body: { name: string, description: string, public: boolean }) {
    return this.http.post<Group>(`${this.BASE_URL}`, body);
  }

  delete(id: string) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  kickMember(groupId: string, memberId: string) {
    return this.http.delete(`${this.BASE_URL}/${groupId}/kick/${memberId}`);
  }

  leave(groupId: string) {
    return this.http.delete(`${this.BASE_URL}/${groupId}/leave`);
  }

  join(groupId: string) {
    return this.http.put(`${this.BASE_URL}/${groupId}/join`, null);
  }

  askToJoin(groupId: string) {
    return this.http.put(`${this.BASE_URL}/${groupId}/ask`, null);
  }

  joinRequests(groupId: string) {
    return this.http.get<GetPendingRequestsDTO>(`${this.BASE_URL}/${groupId}/joinrequests`);
  }

  acceptJoinRequest(groupId: string, memberId: string) {
    return this.http.put(`${this.BASE_URL}/${groupId}/accept/${memberId}`, null);
  }

  rejectJoinRequest(groupId: string, memberId: string) {
    return this.http.put(`${this.BASE_URL}/${groupId}/reject/${memberId}`, null);
  }
}
