import { ProgramItem } from "./ProgramItem.dto";

export class Program {
    id!: string;
    title!: string;
    description!: string;
    author!: string;
    items!: ProgramItem[];
    stack!: string;
    input!: string;
    output!: string;
    public!: boolean;
    created_at!: string;
    updated_at!: string;
}
