import { Profile, UpdateProfileDTO } from '@codingbook/shared';
import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { AlertService } from '../alert.service';
import { inject } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, debounce, debounceTime, mergeMap, pipe, repeat, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { AuthStore } from './auth.store';

export interface ProfileState {
    profiles: Map<string, Profile>;
}

export interface UpdateProfileRxArgs {
    id: string,
    body: UpdateProfileDTO
}

let currentId: string;

export const ProfileStore = signalStore(
    withState<ProfileState>({
        profiles: new Map()
    }),
    withMethods((store, service = inject(ProfileService), authStore = inject(AuthStore), alert = inject(AlertService)) => ({
        getOne: rxMethod<string>(
            pipe(
                mergeMap((id) => {
                    return service.getOne(id).pipe(
                        tapResponse({
                            next: (profile) => {
                                const profiles = new Map(store.profiles());
                                profiles.set(id, profile);
                                patchState(store, { profiles });
                            },
                            error: function (error: unknown): void {
                                console.error(error);
                            }
                        })
                    )
                })
            )
        ),
        getMyProfile: rxMethod<string>(
            pipe(
                tap((id) => {
                    currentId = id;
                }),
                switchMap((id) => {
                    return service.getOne(id).pipe(
                        tapResponse({
                            next: (profile) => {
                                const profiles = new Map(store.profiles());
                                profiles.set(id, profile);
                                patchState(store, { profiles });
                            },
                            error: function (error: unknown): void {
                                console.error(error);
                                throw error;
                            }
                        })
                    )
                }),
                catchError((e) =>
                    service.create({id: currentId}).pipe(
                        tapResponse({
                            next: (profile) => {
                                return service.getOne(currentId).pipe(
                                    tapResponse({
                                        next: (profile) => {
                                            const profiles = new Map(store.profiles());
                                            profiles.set(currentId, profile);
                                            patchState(store, { profiles });
                                        },
                                        error: function (error: unknown): void {
                                            console.error(error);
                                        }
                                    })
                                )
                            },
                            error: function (error: unknown): void {
                                console.error(error);
                            }
                        })
                    )
                ),
                repeat()
            )
        ),
        update: rxMethod<UpdateProfileRxArgs>(
            pipe(
                switchMap((body) => {
                    return service.update(body.id, body.body).pipe(
                        tapResponse({
                            next: () => {
                                alert.info(`Profile updated`);
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to update profile`)
                            }
                        })
                    )
                })
            )
        ),
        follow: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.follow(id).pipe(
                        tapResponse({
                            next: () => {
                                const profiles = new Map(store.profiles());
                                const profile = profiles.get(id)
                                if (!profile) {
                                    throw new Error('Profile not found');
                                }

                                const myId = authStore.profile()?.id;
                                if (!myId) {
                                    throw new Error('User not found');
                                }

                                profile.followers = profile.followers?.concat(myId) ?? [myId];
                                profiles.set(id, profile);
                                patchState(store, { profiles });
                                alert.info(`Followed`);
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to follow`)
                            }
                        })
                    )
                })
            )
        ),
        unfollow: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.unfollow(id).pipe(
                        tapResponse({
                            next: () => {
                                const profiles = new Map(store.profiles());
                                const profile = profiles.get(id)
                                if (!profile) {
                                    throw new Error('Profile not found');
                                }

                                const myId = authStore.profile()?.id;
                                if (!myId) {
                                    throw new Error('User not found');
                                }

                                profile.followers = profile.followers?.filter(follower => follower !== myId) ?? [];
                                profiles.set(id, profile);
                                patchState(store, { profiles });
                                alert.info(`Unfollowed`);
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to unfollow`)
                            }
                        })
                    )
                })
            )
        )
    }))
);