import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationKeys } from '../keys.interface';
import { ButtonComponent, InputSimpleComponent } from '@codingbook/shared-angular';
import { MatDividerModule } from '@angular/material/divider';
import { FormControl, FormGroup } from '@angular/forms';
import { TchatMessageComponent } from './message.component';
import { MessageStore } from '../stores/message.store';
import { ProfileStore } from '../stores/profile.store';
import { Conversation, Message } from '@codingbook/shared';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TchatNewContactComponent } from './newcontact.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    InputSimpleComponent,
    ButtonComponent,
    MatDividerModule,
    TchatMessageComponent,
    RouterModule,
    MatIconModule,
    TchatNewContactComponent
  ],
  selector: 'codingbook-tchat',
  templateUrl: './tchat.component.html',
})
export class TchatComponent {
    translationKeys = TranslationKeys
    formName!: FormGroup;

    messageStore = inject(MessageStore);
    profileStore = inject(ProfileStore);
    selectNewContactActive = false;

    ngOnInit(): void {
        this.formName = new FormGroup({
            search: new FormControl('')
        });

        this.messageStore.getConversations();
    }

    get searchControl() {
      return this.formName.get('search') as FormControl;
    }

    getUser(id: string) {
      if (!id) return;

      const user = this.profileStore.profiles().get(id);

      if (!user) {
        this.profileStore.getOne(id);  
      }

      return user ?? null;
    }

    getLastMessage(conversation: Conversation) {
      const messages = [...conversation.messagesSent, ...conversation.messagesReceived];
      if (messages.length === 0) return 'No message';

      const message = messages.reduce((acc: Message | null, current: Message) => {
        if (!acc) return current;

        return current.created_at > acc.created_at ? current : acc;
      });

      return message?.content ?? 'No message';
    }

    switchMode() {
      this.selectNewContactActive = !this.selectNewContactActive
    }
}
