import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'codingbook-button',
  standalone: true,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.sass'],
  imports: [MatButtonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  type = 'button';
  @Input({ required: true }) text = 'Button';
  @Input({ required: false }) disabled = false;
  @Input({ required: false }) icon = "";
  @Output() out = new EventEmitter();

  onClick() {
    if (this.disabled) {
      return;
    }

    this.out.emit();
  }
}