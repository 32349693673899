import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@codingbook/ngx-env';
import { Role } from '@codingbook/shared';
import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { AuthStore } from '../stores/auth.store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  environment: Environment;
  keycloak: Keycloak;
  readonly authStore = inject(AuthStore);

  constructor(
    @Inject(ENVIRONMENT_TOKEN) environment: Environment 
  ) {
    this.environment = environment;
    
    const options: KeycloakConfig = {
      realm: this.environment.KEYCLOAK_REALM,
      url: this.environment.KEYCLOAK_URL,
      clientId: this.environment.KEYCLOAK_CLIENTID
    };

    this.keycloak = new Keycloak(options);

    this.keycloak.onTokenExpired = () => {
      this.keycloak.updateToken(5);
    };

    this.keycloak.init({ onLoad: "check-sso" }).catch(err => {
      console.error(err);
    })

    this.keycloak.onAuthSuccess = async () => {
      const profile = await this.keycloak.loadUserProfile();
      console.log('onAuthSuccess');
      this.authStore.authenticated(profile);

      localStorage.setItem("keycloak-token", this.keycloak.token || '');
      localStorage.setItem("keycloak-refresh-token", this.keycloak.refreshToken || '');
      localStorage.setItem("keycloak-id-token", this.keycloak.idToken || '');
    }

    this.keycloak.onAuthLogout = async () => {
      console.log('onAuthLogout');
      this.authStore.loggedout();

      localStorage.removeItem("keycloak-token");
      localStorage.removeItem("keycloak-refresh-token");
      localStorage.removeItem("keycloak-id-token");
    }
  }

  async login() {
    await this.keycloak.login();
  }

  async register() {
    await this.keycloak.register();
  }

  async logout() {
    await this.keycloak.logout();
  }

  async getToken() {
    return this.keycloak.token;
  }

  async getUser() {
    return this.keycloak.loadUserProfile();
  }

  async isLoggedIn() {
    console.log(this.keycloak.authenticated)
    return this.keycloak.authenticated;
  }

  async updateToken() {
    return this.keycloak.updateToken(5);
  }
}
