<button
  mat-raised-button
  color="accent"
  type="submit"
  [disabled]="disabled"
  (click)="onClick()"
  class="w-full"
>
@if (icon) {
  <mat-icon>{{ icon }}</mat-icon>
}
@if (text) {
  {{text}}
}
</button>