import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@codingbook/ngx-env';
import { Conversation } from '@codingbook/shared';

@Injectable({
  providedIn: 'root',
})
export class TchatService {

  environment: Environment;
  readonly BASE_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) environment: Environment 
  ) {
    this.environment = environment;
    this.BASE_URL = `${this.environment.SOCIAL_URL}/tchat`;
  }

  getAll() {
    return this.http.get<{conversations: Conversation[]}>(`${this.BASE_URL}`);   
  }
}
