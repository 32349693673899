import { Job, CreateJobDTO, Program } from '@codingbook/shared';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { debounceTime, distinct, exhaustMap, pipe, repeat, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { inject } from '@angular/core';
import { JobService } from '../services/jobs.service';
import { AlertService } from '../alert.service';

export interface JobStore {
    lastJob: Job | null;
    jobs: Job[];
    logs: string[];
}

export const JobStore = signalStore(
    withState<JobStore>({
        lastJob: null,
        jobs: [],
        logs: []
    }),
    withMethods((store, service = inject(JobService), alert = inject(AlertService)) => ({
        execute: rxMethod<CreateJobDTO>(
            pipe(
                tap(() => patchState(store, {lastJob: null})),
                exhaustMap((body) => {
                    // TODO: save the program before executing the job
                    return service.create(body).pipe(
                        tapResponse({
                            next: (job) => {
                                //TODO: get program id for logs req
                                patchState(store, {
                                    lastJob: job
                                });
                                alert.info(`Job created`);
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to execute job`)
                            }
                        })
                    )
                
                })
            )
        ),
        getJobs: rxMethod<Program>(
            pipe(
                switchMap((program) => service.getJobs(program.id)),
                tapResponse({
                    next: (jobs) => {
                        patchState(store, {
                            jobs
                        });
                    },
                    error: function (error: unknown): void {
                        alert.error(`Failed to get jobs`)
                    }
                }),
                repeat()
            )
        ),
        getLogs: rxMethod<string>(
            pipe(
                switchMap((jobId) => service.logs(jobId)),
                tapResponse({
                    next: (logs) => {
                        patchState(store, {
                            logs
                        });
                    },
                    error: function (error: unknown): void {
                        alert.error(`Failed to get logs`)
                    }
                }),
                repeat()
            )
        ),
        clear: rxMethod<void>(
            pipe(
                tap(() => {
                    patchState(store, {
                        lastJob: null,
                        logs: []
                    })
                })
            )
        )
    }))
);