import { Injectable, inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { AuthStore } from '../stores/auth.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  authStore = inject(AuthStore)

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canMatch(): Observable<boolean> | boolean {
    // const keycloakToken = localStorage.getItem('keycloak-token');
    // const keycloakRefreshToken = localStorage.getItem('keycloak-refresh-token');
    // const keycloakIdToken = localStorage.getItem('keycloak-id');
    if (this.authService.keycloak.authenticated) {
      return of(true);
    }

    return this.checkAuthentication();
  }

  private checkAuthentication(): Observable<boolean> {
    const isAuthenticated$ = toObservable(this.authStore.isAuthenticated);

    return isAuthenticated$.pipe(
      debounceTime(300),
      map(isAuthenticated => {
        if (!isAuthenticated) {
          this.router.navigate(['/login']);  // Rediriger vers la page de login si non authentifié
        }
        return isAuthenticated;
      })
    );
  }
}
