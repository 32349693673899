// import { IsNotEmpty, IsOptional, IsString, IsUUID } from "class-validator";
// import { ApiProperty } from "@nestjs/swagger";

export class uuidDTO {
  // @IsNotEmpty()
  // @IsString()
  // @IsUUID()
  // @ApiProperty()
  uuid!: string;
}
