import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withPreloading,
} from '@angular/router';
import { appRoutes } from './app.routes';

import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ENVIRONMENT_TOKEN } from '@codingbook/ngx-env'

import { environment } from '../assets/environments/environment';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { MatDialogModule } from '@angular/material/dialog';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { CodeEditorModule } from '@ngstack/code-editor';

import { AuthInterceptor } from './auth.interceptor';
import { AuthStore } from './stores/auth.store';
import { ProgramStore } from './stores/program.store';
import { EditorStore } from './stores/editor.store';
import { JobStore } from './stores/job.store';
import { LogsStore } from './stores/logs.store';
import { PipelineStore } from './stores/pipeline.store';
import { ProfileStore } from './stores/profile.store';
import { PostStore } from './stores/post.store';
import { CommentStore } from './stores/comment.store';
import { MessageStore } from './stores/message.store';
import { GroupsStore } from './stores/groups.store';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideStore(),
    AuthStore,
    CommentStore,
    PostStore,
    ProfileStore,
    ProgramStore,
    PipelineStore,
    EditorStore,
    JobStore,
    LogsStore,
    MessageStore,
    GroupsStore,
    importProvidersFrom(BrowserModule, MatSnackBarModule, MatDialogModule,
      CodeEditorModule.forRoot({}),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        },
        defaultLanguage: 'fr',
      }),
      MonacoEditorModule.forRoot({
        baseUrl: './assets',
        defaultOptions: { scrollBeyondLastLine: false },
        onMonacoLoad: () => {},
        requireConfig: {
          preferScriptTags: true
        },
        monacoRequire: (<any>window).monacoRequire 
      })
      ),
      provideAnimations(),
      provideHttpClient(
          withInterceptorsFromDi()
      ),
      { provide: ENVIRONMENT_TOKEN, useValue: environment },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    provideRouter(appRoutes,
      withEnabledBlockingInitialNavigation(),
      withComponentInputBinding(),
      withPreloading(PreloadAllModules)
    ),
  ],
};
