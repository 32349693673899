<ng-template #messageContent let-message="message">
    <div matTooltip="{{message.created_at | date: 'medium' }}" [ngSwitch]="getContentType(message.content)">
        <div *ngSwitchCase="'link'">
            <a href="{{message.content}}" class="text-sm hover:underline">{{message.content}}</a>
        </div>
        <p *ngSwitchDefault class="text-sm">{{message.content}}</p>
    </div>
</ng-template>

<ng-template #messageTemplate let-message="message" let-showPicture="showPicture">
    @if (!isMessageFromMe(message)) {
    <div class="flex justify-start gap-2">
        <img [ngClass]="{invisible: !showPicture}" src="/assets/profile/maramzitouni.JPG" alt="Avatar" class="w-8 h-8 rounded-full aspect-square">
        <div class="flex flex-col gap-1 border rounded-xl bg-gray-800 text-white p-2 shadow-sm" [ngClass]="{'items-start': true}">
            <ng-container [ngTemplateOutlet]="messageContent" [ngTemplateOutletContext]="{message}"></ng-container>
        </div>
    </div>
    } @else {
    <div class="flex justify-end gap-2">
        <div class="flex flex-col gap-1 border rounded-xl bg-accent text-white p-2 shadow-sm" [ngClass]="{'items-end': true}">
            <ng-container [ngTemplateOutlet]="messageContent" [ngTemplateOutletContext]="{message}"></ng-container>
        </div>
        <img [ngClass]="{invisible: !showPicture}" src="/assets/profile/maramzitouni.JPG" alt="Avatar" class="w-8 h-8 rounded-full aspect-square">
    </div>
    }
</ng-template>

<div #messages class="flex flex-col gap-4 justify-between w-full h-full overflow-auto">
    <div>
        <div class="sticky top-0 p-2 bg-white shadow-md">
            <h1 [routerLink]="['/profile', profile?.id]" class="text-md font-bold hover:underline underline-offset-2 hover:text-accent duration-100 cursor-pointer">{{profile?.username ?? ''}}</h1>
            <p class="text-sm text-gray-600">{{profile?.status ? profile?.status : translationKeys.noStatus | translate}}</p>
        </div>

        <div class="h-full mt-3">
            <div class="flex flex-col p-2 gap-1">
                @for (message of this.messageStore.messages(); track $index) {
                    @defer (on viewport) {
                    <ng-container
                        [ngTemplateOutletContext]="{message: message, showPicture: getFirstMessageFromSlice().includes($index)}"
                        [ngTemplateOutlet]="messageTemplate"
                    ></ng-container>
                    } @placeholder {
                        <div></div>
                    }
                }
            </div>
        </div>
    </div>


    <div class="px-4 pb-4">
        <codingbook-input-simple [required]="false" label="Message" placeholder="Send message" [formControlSimple]="inputControl"></codingbook-input-simple>
        <codingbook-button [disabled]="!messageStore.ready()" [text]="translationKeys.sendMessage | translate" (out)="sendMessage()"></codingbook-button>
    </div>
    
</div>