import { Comment, CreateCommentDTO, Post } from '@codingbook/shared';
import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { AlertService } from '../alert.service';
import { computed, inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { CommentService } from '../services/comment.service';
import { AuthService } from '../services/auth.service';
import { AuthStore } from './auth.store';

export interface CommentState {
    posts: Map<string, Comment[]>;
}

export interface CreateCommentRxArgs {
    body: CreateCommentDTO;
    id: string;
}

export interface DeleteCommentRxArgs {
    id: string;
    postId: string;
}

export interface LikeCommentRxArgs {
    id: string
    postId: string
}

export interface UnlikeCommentRxArgs {
    id: string
    postId: string
}

export const CommentStore = signalStore(
    withState<CommentState>({
        posts: new Map<string, Comment[]>(),
    }),
    withMethods((store, authStore = inject(AuthStore), service = inject(CommentService), alert = inject(AlertService)) => ({
        create: rxMethod<CreateCommentRxArgs>(
            pipe(
                switchMap((args) => {
                    return service.createPostComment(args.body, args.id).pipe(
                        tapResponse({
                            next: (comment) => {
                                const comments = store.posts().get(args.id) || [];
                                comment.likes = [];
                                comments.push(comment);
                                patchState(store, { posts: store.posts().set(args.id, comments) });
                            },
                            error: function (error: unknown): void {
                                console.error(error);
                                alert.error(`Failed to create comment`)
                            }
                        })
                    )
                })
            )
        ),
        getAllFromPost: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.getAllFromPost(id).pipe(
                        tapResponse({
                            next: (comments) => {
                                patchState(store, { posts: store.posts().set(id, comments) });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to get comments`)
                            }
                        })
                    )
                })
            )
        ),
        delete: rxMethod<DeleteCommentRxArgs>(
            pipe(
                switchMap((id) => {
                    return service.delete(id.id).pipe(
                        tapResponse({
                            next: () => {
                                const comments = store.posts().get(id.id)!.filter((comment) => comment.id !== id.id);
                                patchState(store, { posts: store.posts().set(id.id, comments) });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to delete comment`)
                            }
                        })
                    )
                })
            )
        ),
        like: rxMethod<LikeCommentRxArgs>(
            pipe(
                switchMap((args) => {
                    return service.like(args.id).pipe(
                        tapResponse({
                            next: () => {
                                const posts = store.posts();
                                const comments = posts.get(args.postId);

                                if (!comments) {
                                    throw new Error(`Post with id ${args.id} not found`);
                                }

                                const userId = authStore.profile()?.id;
                                if (!userId) {
                                    throw new Error(`User not found`);
                                }

                                comments.find(comment => comment.id === args.id)?.likes.push(userId);

                                patchState(store, { posts });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to like post`)
                            }
                        })
                    )
                })
            )
        ),
        unlike: rxMethod<UnlikeCommentRxArgs>(
            pipe(
                switchMap((args) => {
                    return service.unlike(args.id).pipe(
                        tapResponse({
                            next: () => {
                                const posts = store.posts();
                                const comments = posts.get(args.postId);

                                if (!comments) {
                                    throw new Error(`Post with id ${args.id} not found`);
                                }

                                const userId = authStore.profile()?.id;
                                if (!userId) {
                                    throw new Error(`User not found`);
                                }

                                const likes = comments.find(comment => comment.id === args.id)?.likes || [];
                                likes.splice(likes.indexOf(userId), 1);

                                patchState(store, { posts });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to unlike post`)
                            }
                        })
                    )
                })
            )
        ),
    }))
);