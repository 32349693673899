import { Post } from '@codingbook/shared';
import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { AlertService } from '../alert.service';
import { computed, inject } from '@angular/core';
import { PostService } from '../services/post.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { AuthStore } from './auth.store';

export interface PostState {
    posts: Post[];
}

export interface CreatePostRxArgs {
    title: string
    body: string
}

export const PostStore = signalStore(
    withState<PostState>({
        posts: [],
    }),
    withMethods((store, authService = inject(AuthStore), service = inject(PostService), alert = inject(AlertService)) => ({
        create: rxMethod<CreatePostRxArgs>(
            pipe(
                switchMap(({ title, body }) => {
                    return service.createPost({
                        title,
                        content: body
                    }).pipe(
                        tapResponse({
                            next: (post) => {
                                post.likes = [];
                                const posts = [...store.posts(), post];
                                patchState(store, { posts });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to create post`)
                            }
                        })
                    )
                })
            )
        ),
        getAll: rxMethod<void>(
            pipe(
                switchMap(() => {
                    return service.getAll().pipe(
                        tapResponse({
                            next: (posts) => {
                                patchState(store, { posts });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to get posts`)
                            }
                        })
                    )
                })
            )
        ),
        getAllFrom: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.getAllFrom(id).pipe(
                        tapResponse({
                            next: (posts) => {
                                patchState(store, { posts });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to get posts`)
                            }
                        })
                    )
                })
            )
        ),
        like: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.like(id).pipe(
                        tapResponse({
                            next: () => {
                                const posts = store.posts();
                                const post = posts.find(post => post.id === id);

                                if (!post) {
                                    throw new Error(`Post with id ${id} not found`);
                                }

                                const userId = authService.profile()?.id;
                                if (!userId) {
                                    throw new Error(`User is not authenticated`);
                                }

                                post.likes.push(userId);

                                patchState(store, { posts });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to like post`)
                            }
                        })
                    )
                })
            )
        ),
        unlike: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.unlike(id).pipe(
                        tapResponse({
                            next: () => {
                                const posts = store.posts();
                                const post = posts.find(post => post.id === id);

                                const userId = authService.profile()?.id;
                                if (!userId) {
                                    throw new Error(`User is not authenticated`);
                                }

                                post?.likes.splice(post.likes.indexOf(userId), 1);
                                
                                patchState(store, { posts });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to unlike post`)
                            }
                        })
                    )
                })
            )
        ),
    }))
);