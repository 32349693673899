import { IsString } from "class-validator";

export class SendMessageDTO {
    @IsString()
    type!: string;
    @IsString()
    content!: string;
    @IsString()
    to!: string;
}

export class SendIDDTO {
    @IsString()
    type!: string;
    @IsString()
    id!: string;
}

export class NewTchatDTO {
    @IsString()
    type!: string;
    @IsString()
    message!: string;
    @IsString()
    conversation!: string;
    @IsString()
    created_at!: string;
}
