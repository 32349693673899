import { Inject, Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthStore } from './stores/auth.store';
import { ENVIRONMENT_TOKEN, Environment } from '@codingbook/ngx-env';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  authStore = inject(AuthStore);

  constructor(
    private readonly router: Router,
    @Inject(ENVIRONMENT_TOKEN) environment: Environment
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // if (!this.userService.isLoggedIn()) {
    //   return next.handle(request);
    // }

    // const token = this.userService.getJWT();
    // if (!token) {
    //   return next.handle(request);
    // }

    // if (!request.url.includes(environment) && !request.url.includes('192.168.1.12')) {
    //   console.log('Not scrypteur.com or localhost')
    //   console.log(request.url)
    //   return next.handle(request);
    // }

    // if (this.userService.isExpired(token)) {
    //   this.userService.logout();
    //   this.router.navigate(['/']);
    //   return next.handle(request);
    // }

    const id = this.authStore.profile()?.id;
    if (!id) {
      return next.handle(request);
    }

    console.log('Adding user id to request:', id);
    const authRequest = request.clone({
      headers: request.headers.set('x-user-id', id)
    });

    return next.handle(authRequest);
  }
}
