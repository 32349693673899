import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from "@angular/core";
import { ProfileStore } from "../stores/profile.store";
import { TranslationKeys } from "../keys.interface";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ButtonComponent } from "@codingbook/shared-angular";
import { TranslateModule } from "@ngx-translate/core";
import { MessageStore } from "../stores/message.store";
import { AuthStore } from "../stores/auth.store";
import { Subscription } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";
import { RouterModule } from "@angular/router";
import { Profile } from "@codingbook/shared";

@Component({
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        MatButtonModule,
        ButtonComponent,
        RouterModule
    ],
    selector: 'codingbook-tchat-new-contact',
    templateUrl: './newcontact.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TchatNewContactComponent implements OnInit, OnDestroy {
    translationKeys = TranslationKeys

    messageStore = inject(MessageStore);
    profileStore = inject(ProfileStore);
    authStore = inject(AuthStore);

    profiles$: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
    ) {
        this.profiles$ = toObservable(this.profileStore.profiles).subscribe(_profile => {
            this.cdr.detectChanges();
        });
    }

    ngOnInit(): void {
        const id = this.authStore.profile()?.id;
        if (!id) {
            return;
        }

        this.profileStore.getOne(id)
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        this.profiles$.unsubscribe();
    }

    get contacts(): Profile[] {
        const id = this.authStore.profile()?.id;
        if (!id) {
            return [];
        }

        const follows = this.profileStore.profiles().get(id)?.follows || [];
        const contacts = follows.map((id) => this.profileStore.profiles().get(id)).filter((profile) => !!profile) as Profile[];

        return contacts.filter((profile) => !this.messageStore.conversations().has(profile.id ?? '')) as Profile[];
    }

    getUser(id: string) {
        return this.profileStore.profiles().get(id) ?? null;
    }
}