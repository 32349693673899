@if (contacts.length > 0) {
    <p>Suggestions :</p>
    <br>
    @for (contact of contacts; track $index) {
        @defer (on viewport) {
            <div (click)="this.messageStore.startConversation(contact?.id ?? '')" class="flex gap-4 p-2 hover:bg-gray-200 rounded-lg">
                <img src="/assets/profile/maramzitouni.JPG" alt="Avatar" class="w-10 h-10 rounded-full">
                <div class="w-full">
                    <div class="flex justify-between w-full">
                        <h1 [routerLink]="['/profile', contact.id]" class="text-md font-bold hover:underline underline-offset-2 hover:text-accent duration-100 cursor-pointer">{{getUser(contact.id ?? '')?.username || 'Unknown'}}</h1>
                        <p class="text-sm text-gray-600">12m</p>
                    </div>
                </div>
            </div>
        } @placeholder {
            <div></div>
        }
    }
} @else {
    <p>No contacts found</p>
}