import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@codingbook/ngx-env';
import { CreateJobDTO, Program, UpdateProgramDTO } from '@codingbook/shared';
import { Job } from '@codingbook/shared';

@Injectable({
  providedIn: 'root',
})
export class JobService {

  environment: Environment;
  readonly BASE_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) environment: Environment 
  ) {
    this.environment = environment;
    this.BASE_URL = `${this.environment.GATEWAY_URL}/jobs`;
  }

  create(body: CreateJobDTO) {
    const formData = new FormData();
    formData.append('program_id', body.program_id);
    formData.append('variables', JSON.stringify(Object.fromEntries(body.variables)));
    if (body.file) formData.append('file', body.file);

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post<Job>(`${this.BASE_URL}`, formData, { headers });
  }

  getJobs(id: string) {
    return this.http.get<Job[]>(`${this.BASE_URL}/${id}`);
  }

  logs(id: string) {
    return this.http.get<string[]>(`${this.BASE_URL}/${id}/logs`);
  }
}
