import { Job } from "./Job.dto";

export type CreateJob = Omit<Job, "id" | "name" | "state" | "input" | "program" | "created_at" | "updated_at" | "duration">



export class CreateJobDTO implements CreateJob {
    program_id!: string;
    variables!: Map<string, string>;
    file!: File | undefined;
}