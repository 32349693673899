
import { IsBoolean, IsNotEmpty } from 'class-validator';

export class Profile {
    @IsBoolean()
    busy!: boolean;

    @IsNotEmpty()
    bio!: string;

    @IsNotEmpty()
    status!: string;

    followers?: string[];
    follows?: string[];

    id?: string;

    username?: string;
}
