<ng-template #tchatcontact let-conversation="conversation">
    <div [ngClass]="{
        'bg-gray-200 border shadow-sm': messageStore.conversationsWithNewMessages().has(conversation.contact) || (this.messageStore.selectedConversation()?.contact == conversation.contact)}
        " (click)="this.messageStore.selectConversation(conversation.contact)" class="flex gap-4 p-2 hover:bg-gray-200 rounded-lg">
        <img src="/assets/profile/maramzitouni.JPG" alt="Avatar" class="w-10 h-10 rounded-full">
        <div class="w-full">
            <div class="flex justify-between w-full">
                <h1 [routerLink]="['/profile', conversation.contact]" class="text-md font-bold hover:underline underline-offset-2 hover:text-accent duration-100 cursor-pointer">{{getUser(conversation.contact)?.username || 'Unknown'}}</h1>
                <p class="text-sm text-gray-600">12m</p>
            </div>
            <p class="text-sm text-gray-600">{{getLastMessage(conversation)}}</p>
        </div>
    </div>
</ng-template>


<div class="flex fixed right-14 bottom-0 w-5/12 h-4/6 shadow-lg border rounded-md pb-2 bg-white z-50 mat-elevation-z8">
    <div class="flex flex-col gap-3 w-3/6 h-full border-r">
        <div class="flex justify-between my-4 ml-3 items-center align-middle px-4">
            <div class="flex gap-3 items-center align-middle">
                <h1 class="text-lg font-bold">Messages</h1>
                <span class="bg-gray-200 text-black font-semibold rounded-full p-1 text-sm">{{this.messageStore.conversations().size}}</span>
            </div>
            <mat-icon (click)="switchMode()" class="hover:underline underline-offset-2 hover:text-accent duration-100 cursor-pointer">add_comment</mat-icon>
        </div>

        <mat-divider></mat-divider>

        <div class="px-3 overflow-auto">
            <codingbook-input-simple [required]="false" [label]="'Search'" [placeholder]="'Search messages'" [formControlSimple]="searchControl"></codingbook-input-simple>

            @if (selectNewContactActive) {
                <codingbook-tchat-new-contact></codingbook-tchat-new-contact>
            } @else {
                <div class="flex flex-col gap-2 py-1">
                    @for (conversation of this.messageStore.conversations() | keyvalue; track $index) {
                        @defer (on viewport) {
                        <ng-container
                        [ngTemplateOutletContext]="{conversation: conversation.value}"
                        [ngTemplateOutlet]="tchatcontact"
                        ></ng-container>
                        } @placeholder {
                            <div></div>
                        }
                    }
                </div>
            }
        </div>
        
    </div>
    <codingbook-tchat-message *ngIf="this.messageStore.selectedConversation()" class="w-3/6 overflow-auto h-full"></codingbook-tchat-message>
</div>
