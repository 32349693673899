import { CreateProgramDTO, Program, Stack, UpdateProgramDTO } from '@codingbook/shared';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { EMPTY, debounceTime, distinct, exhaustMap, from, mergeMap, of, pipe, switchMap, tap, toArray } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { computed, inject } from '@angular/core';
import { ProgramService } from '../services/program.service';
import { AlertService } from '../alert.service';
import { AuthStore } from './auth.store';

type ProgramsMap = Map<string, Program>;

export interface UpdateProgramRxArgs {
    id: string,
    body: UpdateProgramDTO
}

export interface ProgramState {
    loadingInProgress: boolean;
    programs: ProgramsMap;
    likes: Map<string, Set<string>>;
    owned: Set<string>;
    currentId: string;
    stacks: Stack[];
    IOs: string[];
}

export const ProgramStore = signalStore(
    withState<ProgramState>({
        loadingInProgress: false,
        programs: new Map<string, Program>(),
        likes: new Map<string, Set<string>>(),
        owned: new Set<string>(),
        currentId: '',
        stacks: [],
        IOs: []
    }),
    withComputed((store) => {
        return {
            program: computed(() => {
                return store.programs().get(store.currentId());
            })
        }
    }),
    withMethods((store, service = inject(ProgramService), authStore = inject(AuthStore), alert = inject(AlertService)) => ({
        setCurrentProgram: rxMethod<string>(
            pipe(
                tap((id) => {
                    patchState(store, { currentId: id });
                })
            )
        ),
        like: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.like(id).pipe(
                        tapResponse({
                            next: () => {
                                const likes = new Map(store.likes());
                                const currentLikes = likes.get(id) || new Set();
                                currentLikes.add(authStore.profile()?.id || '');

                                likes.set(id, currentLikes);
                                patchState(store, { likes });
                            },
                            error: function (error: unknown): void {
                                throw new Error('Function not implemented.');
                            }
                        })
                    )
                })
            )
        ),
        unlike: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.unlike(id).pipe(
                        tapResponse({
                            next: () => {
                                const likes = new Map(store.likes());
                                const currentLikes = likes.get(id) || new Set();
                                currentLikes.delete(authStore.profile()?.id || '');

                                likes.set(id, currentLikes);
                                patchState(store, { likes });
                            },
                            error: function (error: unknown): void {
                                throw new Error('Function not implemented.');
                            }
                        })
                    )
                })
            )
        ),
        create: rxMethod<CreateProgramDTO>(
            pipe(
                switchMap((body) => {
                    return service.create(body).pipe(
                        tapResponse({
                            next: (program) => {
                                const programs: ProgramsMap = new Map(store.programs());
                                programs.set(program.id, program);

                                const owned = new Set(store.owned());
                                owned.add(program.id);
                                patchState(store, { programs, owned });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to create program`)
                            }
                        })
                    )
                })
            )
        ),
        update: rxMethod<UpdateProgramRxArgs>(
            pipe(
                switchMap((program) => {
                    return service.update(program.id, program.body).pipe(
                        tapResponse({
                            next: (program) => {
                                const programs: ProgramsMap = new Map(store.programs());
                                programs.set(program.id, program);
                                patchState(store, { programs });
                                alert.info(`Program saved`);
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to update program`);
                            }
                        })
                    )
                })
            )
        ),
        delete: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.delete(id).pipe(
                        tapResponse({
                            next: () => {
                                const programs: ProgramsMap = new Map(store.programs());
                                programs.delete(id);
                                patchState(store, { programs });
                            },
                            error: function (error: unknown): void {
                                throw new Error('Function not implemented.');
                            }
                        })
                    )
                })
            )
        ),
        refresh: rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loadingInProgress: true })),
                exhaustMap(() => {
                    return service.getAll()
                        .pipe(
                            tapResponse({
                                next: (programs) => {
                                    const programsMap = new Map<string, Program>();
                                    programs.programs.forEach(program => {
                                        programsMap.set(program.id, program);
                                    });

                                    patchState(store, {
                                        programs: programsMap,
                                        owned: new Set(programs.owned),
                                    });
                                },
                                error: () => {
                                    return EMPTY;
                                },
                                complete: () => {
                                    patchState(store, { loadingInProgress: false });
                                }
                            }),
                            switchMap(() => from([...store.programs().keys()])),
                            mergeMap((id) => {
                                return service.likes(id).pipe(
                                    tapResponse({
                                        next: (likes) => {
                                            const likesMap = new Map(store.likes());
                                            likesMap.set(id, new Set(likes));
                                            patchState(store, { likes: likesMap });
                                        },
                                        error: () => {
                                            return EMPTY;
                                        }
                                    })
                                )
                            }),
                        )   
                }),
                tap(() => patchState(store, { loadingInProgress: false })),
            )
        ),
        getStacks: rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loadingInProgress: true })),
                exhaustMap(() => {
                    return service.getStacks()
                        .pipe(
                            tapResponse({
                                next: (stacks) => {
                                    patchState(store, { stacks, loadingInProgress: false });
                                },
                                error: () => {
                                    patchState(store, { loadingInProgress: false });
                                }
                            })
                        )
                }),
            )
        ),
        getIOs: rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loadingInProgress: true })),
                exhaustMap(() => {
                    return service.getIOs()
                        .pipe(
                            tapResponse({
                                next: (ios) => {
                                    patchState(store, { IOs: ios, loadingInProgress: false });
                                },
                                error: () => {
                                    patchState(store, { loadingInProgress: false });
                                }
                            })
                        )
                }),
            )
        ),
    })),
);