export class Pipeline {
    id!: string;
    title!: string;
    description!: string;
    author!: string;
    // in execution order
    programs!: string[];
    public!: boolean;
    created_at!: string;
    updated_at!: string;
}
