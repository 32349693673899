import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { catchError, debounceTime, delay, distinct, exhaustMap, mergeMap, pipe, repeat, skipWhile, subscribeOn, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { inject } from '@angular/core';
import { AlertService } from '../alert.service';
import { GroupsService } from '../services/groups.service';
import { Group } from '@codingbook/shared';
import { AuthStore } from './auth.store';


export interface CreateGroupRxArgs {
    name: string
    description: string
    public: boolean
}

export interface KickMemberRxArgs {
    groupId: string
    memberId: string
}

export interface AcceptRequestRxArgs {
    groupId: string
    memberId: string
}

export interface RejectRequestRxArgs {
    groupId: string
    memberId: string
}

export interface GroupsStore {
    groups: Map<string, Group>;
    pendingRequests: Map<string, string[]>;
    loadingInProgress: boolean;
}

export const GroupsStore = signalStore(
    withState<GroupsStore>({
        groups: new Map<string, Group>(),
        pendingRequests: new Map<string, string[]>(),
        loadingInProgress: false
    }),
    withMethods((store, service = inject(GroupsService), authStore = inject(AuthStore), alert = inject(AlertService)) => ({
        refresh: rxMethod<void>(
            pipe(
                delay(200),
                skipWhile(() => !authStore.authenticated),
                tap(() => patchState(store, { loadingInProgress: true })),
                exhaustMap(() => {
                    return service.getAll()
                        .pipe(
                            tapResponse({
                                next: (response) => {
                                    const groups = new Map();
                                    for (const group of response.groups) {
                                        groups.set(group.id, group);
                                    }
                                    
                                    patchState(store, { groups });
                                },
                                error: (error) => {
                                    patchState(store, { loadingInProgress: false });
                                },
                                complete: () => {
                                    patchState(store, { loadingInProgress: false });
                                }
                            })
                        );
                }),
                tap((key) => {
                    patchState(store, { pendingRequests: new Map() });
                }),
                switchMap(() => {
                    const groups = store.groups();
                    const keys = Array.from(groups.keys());
                    return keys;
                }),
                mergeMap((key) => {
                    return service.joinRequests(key)
                        .pipe(
                            tapResponse({
                                next: (response) => {
                                    const pendingRequests = new Map(store.pendingRequests());
                                    pendingRequests.set(key, response.pendingRequests);
                                    patchState(store, { pendingRequests });
                                },
                                error: (error) => {
                                    alert.error(`Failed to get join requests for group ${key}`);
                                }
                            })
                        );
                }),
                repeat()
            )
        ),
        delete: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.delete(id)
                        .pipe(
                            tapResponse({
                                next: () => {
                                    const groups = new Map(store.groups());
                                    groups.delete(id);
                                    patchState(store, { groups });
                                },
                                error: function (error: unknown): void {
                                    alert.error(`Failed to delete group`)
                                }
                            })
                        )
                })
            )
        ),
        kickMember: rxMethod<KickMemberRxArgs>(
            pipe(
                switchMap(({ groupId, memberId }) => {
                    return service.kickMember(groupId, memberId)
                        .pipe(
                            tapResponse({
                                next: () => {
                                    const groups = new Map(store.groups());
                                    const group = groups.get(groupId);
                                    if (!group) {
                                        return;
                                    }
                                    group.members = group.members.filter(member => member !== memberId);
                                    groups.set(groupId, group);
                                    patchState(store, { groups });
                                },
                                error: function (error: unknown): void {
                                    alert.error(`Failed to kick member`)
                                }
                            })
                        )
                })
            )
        ),
        create: rxMethod<CreateGroupRxArgs>(
            pipe(
                switchMap((args) => {
                    return service.create(args).pipe(
                        catchError((error) => {
                            alert.error(`Failed to create group`);
                            patchState(store, { loadingInProgress: false });
                            return [];
                        }
                    ))
                }),
                tap((group) => {
                    alert.info(`Group created`)
                    const groups = new Map(store.groups());
                    groups.set(group.id, group);
                    patchState(store, { loadingInProgress: false, groups });
                })
            )
        ),
        leave: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.leave(id)
                        .pipe(
                            tapResponse({
                                next: () => {
                                    const groups = new Map(store.groups());
                                    groups.delete(id);
                                    patchState(store, { groups });
                                },
                                error: function (error: unknown): void {
                                    alert.error(`Failed to leave group`)
                                }
                            })
                        )
                })
            )
        ),
        askToJoin: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.askToJoin(id)
                        .pipe(
                            tapResponse({
                                next: () => {
                                    alert.info(`Request to join group sent`)
                                },
                                error: function (error: unknown): void {
                                    alert.error(`Failed to send request to join group`)
                                }
                            })
                        )
                })
            )
        ),
        join: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.join(id)
                        .pipe(
                            tapResponse({
                                next: () => {
                                    const groups = new Map(store.groups());
                                    const group = groups.get(id);
                                    if (!group) {
                                        return;
                                    }

                                    const profile = authStore.profile()?.id;
                                    if (!profile) {
                                        return;
                                    }

                                    group.members.push(profile);
                                    groups.set(id, group);

                                    patchState(store, { groups });
                                },
                                error: function (error: unknown): void {
                                    alert.error(`Failed to join group`)
                                }
                            })
                        )
                })
            )
        ),
        acceptRequest: rxMethod<AcceptRequestRxArgs>(
            pipe(
                switchMap(({ groupId, memberId }) => {
                    return service.acceptJoinRequest(groupId, memberId)
                        .pipe(
                            tapResponse({
                                next: () => {
                                    const pendingRequests = new Map(store.pendingRequests());
                                    const requests = pendingRequests.get(groupId);
                                    if (!requests) {
                                        return;
                                    }
                                    pendingRequests.set(groupId, requests.filter(request => request !== memberId));

                                    const groups = new Map(store.groups());
                                    const group = groups.get(groupId);
                                    if (!group) {
                                        return;
                                    }

                                    group.members.push(memberId);
                                    groups.set(groupId, group);

                                    patchState(store, { pendingRequests, groups });
                                },
                                error: function (error: unknown): void {
                                    alert.error(`Failed to accept request`)
                                }
                            })
                        )
                }),
                repeat()
            )
        ),
        rejectRequest: rxMethod<RejectRequestRxArgs>(
            pipe(
                switchMap(({ groupId, memberId }) => {
                    return service.rejectJoinRequest(groupId, memberId)
                        .pipe(
                            tapResponse({
                                next: () => {
                                    const pendingRequests = new Map(store.pendingRequests());
                                    const requests = pendingRequests.get(groupId);
                                    if (!requests) {
                                        return;
                                    }
                                    pendingRequests.set(groupId, requests.filter(request => request !== memberId));

                                    patchState(store, { pendingRequests });
                                },
                                error: function (error: unknown): void {
                                    alert.error(`Failed to reject request`)
                                }
                            })
                        )
                }),
                repeat()
            )
        )
    }))
);
