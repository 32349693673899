import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationKeys } from '../keys.interface';
import { ButtonComponent, InputSimpleComponent } from '@codingbook/shared-angular';
import { MatDividerModule } from '@angular/material/divider';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageStore } from '../stores/message.store';
import { ProfileStore } from '../stores/profile.store';
import { Subscription } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { Message, Profile } from '@codingbook/shared';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    InputSimpleComponent,
    ButtonComponent,
    MatDividerModule,
    RouterModule,
    MatTooltipModule
  ],
  selector: 'codingbook-tchat-message',
  templateUrl: './message.component.html',
})
export class TchatMessageComponent implements OnInit, OnDestroy {
    translationKeys = TranslationKeys
    formName!: FormGroup;
    @Input() showPicture: boolean = false;

    @ViewChild('messages') private messagesBox!: ElementRef<HTMLDivElement>;

    messageStore = inject(MessageStore);
    profileStore = inject(ProfileStore);
    profile$: Subscription;
    profile: Profile | null = null;

    constructor() {
      this.profile$ = toObservable(this.messageStore.contact).subscribe((id) => {
        if (id) {
          this.profile = this.getUser(id);
        }
      });
    }

    ngOnInit(): void {
        this.formName = new FormGroup({
            input: new FormControl('')
        });

        const interval = setInterval(() => {
          this.scrollToBottom();
          clearInterval(interval);
        }, 100);
    }

    scrollToBottom() {
      if (!this.messagesBox) return;
  
      this.messagesBox.nativeElement.scrollTo(0, this.messagesBox.nativeElement.scrollHeight)
    }

    ngOnDestroy(): void {
      this.profile$.unsubscribe();
    }

    get inputControl() {
      return this.formName.get('input') as FormControl;
    }

    sendMessage() {
        const message = this.inputControl.value
        if (!message) return;

        const to = this.profile?.id ?? ''
        if (!to) return;

        this.messageStore.sendMessageTo({
            message: this.inputControl.value,
            to
        });

        this.inputControl.reset();
        this.scrollToBottom();
    }

    getUser(id: string) {
      if (!id) return null;

      const user = this.profileStore.profiles().get(id);

      if (!user) {
        this.profileStore.getOne(id);  
        return null;
      }

      return user ?? null;
    }

    isMessageFromMe(message: Message) {
      return this.messageStore.messagesSent().some((m) => (m.content === message.content) && (m.created_at === message.created_at));
    }

    getFirstMessageFromSlice() {
      const indexes: number[] = [];

      if (this.messageStore.messages().length === 0) return indexes
      let lastFromOther = this.isMessageFromMe(this.messageStore.messages()[0]) ? true : false;
      for (let i = 0; i < this.messageStore.messages().length; i++) {
        const message = this.messageStore.messages()[i];
        const isFromOther = this.isMessageFromMe(message) ? false : true;

        if (lastFromOther && !isFromOther) {
          indexes.push(i);
        }

        if (!lastFromOther && isFromOther) {
          indexes.push(i);
        }

        lastFromOther = isFromOther;
      }

      return indexes;
    }

    getContentType(content: string) {
      if (content.includes('://')) {
        return 'link';
      }

      return
    }
}
