/* eslint-disable no-case-declarations */
import { computed, inject } from '@angular/core';
import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { firstValueFrom, pipe, switchMap, tap } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '@codingbook/ngx-env';
import { Conversation, NewTchatDTO, SendIDDTO, SendMessageDTO } from '@codingbook/shared';
import { plainToInstance } from 'class-transformer';
import { AlertService } from '../alert.service';
import { TchatService } from '../services/tchat.service';
import { AuthStore } from './auth.store';
import { ProfileStore } from './profile.store';

export interface SendMessageToRxArgs {
    message: string;
    to: string;
}

export interface MessageState {
    ws: WebSocket | null;
    ready: boolean;
    loadingInProgress: boolean;
    conversations: Map<string, Conversation>;
    conversationsWithNewMessages: Set<string>;
    selectedConversation: Conversation | null;
    newConversationId: string | null;
    tchatVisible: boolean;
}

const messageStateInitial: MessageState = {
    ws: null,
    ready: false,
    loadingInProgress: false,
    conversations: new Map(),
    conversationsWithNewMessages: new Set(),
    selectedConversation: null,
    newConversationId: null,
    tchatVisible: false
};

export const MessageStore = signalStore(
    withState<MessageState>(messageStateInitial),
    withComputed((store) => {
        return {
            messagesSent: computed(() => {
                const selection = store.selectedConversation();
                if (!selection) {
                    return []
                }

                return selection.messagesSent;
            }),
            messagesReceived: computed(() => {
                const selection = store.selectedConversation();
                if (!selection) {
                    return []
                }

                return selection.messagesReceived;
            }),
            messages: computed(() => {
                const selection = store.selectedConversation();
                if (!selection) {
                    return []
                }

                return [
                    ...selection.messagesReceived.map((m) => {
                        return {
                            ...m,
                            created_at: m.created_at,
                            sent: false
                        }
                    }),
                    ...selection.messagesSent.map((m) => {
                        return {
                            ...m,
                            created_at: m.created_at,
                            sent: true
                        }
                    })
                ].sort((a, b) => {
                    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                });
            }),
            contact: computed(() => {
                const selection = store.selectedConversation();
                if (!selection) {
                    return null
                }

                return selection.contact;
            }),
        }
    }),
    withMethods((store, environment = inject(ENVIRONMENT_TOKEN), authStore = inject(AuthStore), tchatService = inject(TchatService), profileStore = inject(ProfileStore), alert = inject(AlertService)) => ({
        showTchat: () => {
            patchState(store, {
                tchatVisible: true
            });
        },
        hideTchat: () => {
            patchState(store, {
                tchatVisible: false
            });
        },
        selectConversation: (id: string) => {
            const conversationsWithNewMessages = new Set(store.conversationsWithNewMessages());
            conversationsWithNewMessages.delete(id);

            patchState(store, {
                selectedConversation: store.conversations().get(id) ?? null,
                conversationsWithNewMessages
            })
        },
        startConversation: (id: string) => {
            const conversations = new Map(store.conversations());

            if (!conversations.has(id)) {
                const newConversation = new Conversation();
                newConversation.contact = id;
                newConversation.messagesReceived = [];
                newConversation.messagesSent = [];
                conversations.set(id, newConversation);
            }

            patchState(store, {
                conversations,
                selectedConversation: conversations.get(id),
                newConversationId: id,
                tchatVisible: true
            });
        },
        getConversations: rxMethod<void>(
            pipe(
                switchMap(() => {
                    return tchatService.getAll().pipe(
                        tapResponse({
                            next: (conversations) => {
                                if (store.newConversationId() && !conversations.conversations.some((c) => c.contact === store.newConversationId())) {
                                    conversations.conversations.push({
                                        contact: store.newConversationId() ?? '',
                                        messagesReceived: [],
                                        messagesSent: []
                                    })
                                }

                                patchState(store, {
                                    conversations: new Map(conversations.conversations.map((c) => [c.contact, c]))
                                });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to get conversations`)
                            }
                        })
                    )
                })
            )
        ),
        connect: rxMethod<void>(
            pipe(
                tap(() => {
                    let shouldReconnect = true;
                    // if (!authStore.isAuthenticated()) return;
                    
                    setInterval(() => {
                        if (!shouldReconnect) return;
                        if (store.ws()) return;

                        console.log('connect')
                        const ws = new WebSocket(`${environment.WEBSOCKET_SOCIAL_URL}`);
                        ws.onmessage = (event) => {
                            const message = JSON.parse(event.data) as Record<string, unknown>;
                            const type = message?.['type'] as string;
                            console.log(message)

                            switch (type) {
                                case "NewTchatDTO":
                                    const newTchatDTO = plainToInstance(NewTchatDTO, message);
                                    const messageReceived = {
                                        content: newTchatDTO.message,
                                        created_at: new Date().toISOString(),
                                    };

                                    const conversations = new Map(store.conversations());
                                    const oldSelectedConversation = conversations.get(newTchatDTO.conversation) ?? new Conversation();
                                    const newConversation = new Conversation();
                                    newConversation.contact = newTchatDTO.conversation;
                                    newConversation.messagesSent = oldSelectedConversation.messagesSent;
                                    newConversation.messagesReceived = [...oldSelectedConversation.messagesReceived, messageReceived];

                                    conversations.set(newConversation.contact, newConversation);

                                    const conversationsWithNewMessages = new Set(store.conversationsWithNewMessages());

                                    if (store.selectedConversation()?.contact !== newTchatDTO.conversation) {
                                        conversationsWithNewMessages.add(newTchatDTO.conversation);
                                        patchState(store, {
                                            conversationsWithNewMessages
                                        });
                                    }

                                    patchState(store, {
                                        conversations,
                                        selectedConversation: newConversation,
                                    });

                                    const username = profileStore.profiles().get(newTchatDTO.conversation)?.username;
                                    alert.info(`New message from ${username}`);
                                    break;
                                default:
                                    console.warn('Unknown message type', type);
                                    break;
                            }
                        }
                        ws.onclose = (e) => {
                            console.log('Connection closed');
                            patchState(store, {
                                ready: false,
                                ws: null,
                            });
                        }
                        ws.onerror = (error) => {
                            console.error('WebSocket error:', error);
                            shouldReconnect = true;
                        },
                        ws.onopen = async () => {
                            console.log('Connection open');
                            const sendIDDTO = new SendIDDTO();
                            sendIDDTO.type = SendIDDTO.name
                            sendIDDTO.id = authStore.profile()?.id ?? '';

                            if (!sendIDDTO.id) {
                                return;
                            }

                            ws.send(JSON.stringify(sendIDDTO));

                            patchState(store, {
                                ready: true
                            });

                            try {
                                const conversations = await firstValueFrom(tchatService.getAll());
                                if (store.newConversationId()) {
                                    conversations.conversations.push({
                                        contact: store.newConversationId() ?? '',
                                        messagesReceived: [],
                                        messagesSent: []
                                    })
                                }

                                patchState(store, {
                                    conversations: new Map(conversations.conversations.map((c) => [c.contact, c]))
                                });
                            } catch (error) {
                                alert.error(`Failed to get conversations`)
                            }
                        }

                        patchState(store, {
                            ws
                        });

                    }, 500);
                })
            )
        ),
        disconnect: rxMethod<void>(
            pipe(
                tap(() => {
                    const ws = store.ws();
                    if (!ws) throw new Error('WebSocket not found');

                    patchState(store, messageStateInitial);

                    ws.close();
                })
            )
        ),
        setLoading: rxMethod<boolean>(
            pipe(
                tap((x) => {
                    patchState(store, {
                        loadingInProgress: x
                    });
                })
            )
        ),
        sendMessageTo: rxMethod<SendMessageToRxArgs>(
            pipe(
                tap((message) => {
                    if (!store.ready || !store.ws()) {
                        alert.error('Tchat not ready');
                        return;
                    }

                    const sendMessageDTO = new SendMessageDTO();
                    sendMessageDTO.type = SendMessageDTO.name
                    sendMessageDTO.to = message.to;
                    sendMessageDTO.content = message.message;

                    store.ws()?.send(JSON.stringify(sendMessageDTO));

                    const newMessage = {
                        content: message.message,
                        created_at: new Date().toISOString(),
                    };

                    const selectedConversation = new Conversation();
                    selectedConversation.contact = message.to;
                    selectedConversation.messagesSent = store.selectedConversation()?.messagesSent ?? [];
                    selectedConversation.messagesReceived = store.selectedConversation()?.messagesReceived ?? [];

                    if (!selectedConversation) throw new Error('No conversation selected');

                    selectedConversation.messagesSent.push(newMessage);

                    const conversations = new Map(store.conversations());
                    conversations.set(selectedConversation?.contact ?? '', selectedConversation);

                    patchState(store, {
                        selectedConversation,
                        conversations,
                        newConversationId: (store.newConversationId() === selectedConversation.contact) ? null : store.newConversationId()
                    });                     
                })
            )
        ),
    }))
);