import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@codingbook/ngx-env';
import { CreatePostDTO, Post } from '@codingbook/shared';

@Injectable({
  providedIn: 'root',
})
export class PostService {

  environment: Environment;
  readonly BASE_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) environment: Environment 
  ) {
    this.environment = environment;
    this.BASE_URL = `${this.environment.SOCIAL_URL}/post`;
  }

  createPost(body: CreatePostDTO) {
    return this.http.post<Post>(`${this.BASE_URL}`, body);   
  }

  getAll() {
    return this.http.get<Post[]>(`${this.BASE_URL}`);   
  }

  getAllFrom(profileId: string) {
    return this.http.get<Post[]>(`${this.BASE_URL}/profile/${profileId}`);   
  }

  delete(id: string) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  like(id: string) {
    return this.http.post(`${this.BASE_URL}/${id}/like`, {});
  }

  unlike(id: string) {
    return this.http.post(`${this.BASE_URL}/${id}/unlike`, {});
  }

  getLikes(id: string) {
    return this.http.get<number>(`${this.BASE_URL}/${id}/likes`);
  }
}
