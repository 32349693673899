import { CreatePipelineDTO, CreateProgramDTO, Job, Pipeline, PipelineExecution, Program, UpdatePipelineDTO } from '@codingbook/shared';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { debounceTime, distinct, exhaustMap, filter, from, mergeMap, pipe, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { computed, inject } from '@angular/core';
import { AlertService } from '../alert.service';
import { PipelineService } from '../services/pipeline.service';
import { ProfileStore } from './profile.store';

type PipelinesMap = Map<string, Pipeline>;

export interface UpdatePipelineRxArgs {
    id: string,
    body: UpdatePipelineDTO
}

export interface ExecPipelineRxArgs {
    pipeline: Pipeline,
    variables: Map<string, string>
}

export interface PipelineState {
    loadingInProgress: boolean;
    pipelines: PipelinesMap;
    jobs: Map<string, Map<string, Job>>,
    executions: Record<string, string[]>,
    executions_data: Map<string, PipelineExecution>
}

export const PipelineStore = signalStore(
    withState<PipelineState>({
        loadingInProgress: false,
        pipelines: new Map(),
        jobs: new Map(),
        executions: {},
        executions_data: new Map()
    }),
    withMethods((store, service = inject(PipelineService), alert = inject(AlertService), profileStore = inject(ProfileStore)) => ({
        create: rxMethod<CreatePipelineDTO>(
            pipe(
                switchMap((body) => {
                    return service.create(body).pipe(
                        tapResponse({
                            next: (pipeline) => {
                                const pipelines: PipelinesMap = new Map(store.pipelines());
                                pipelines.set(pipeline.id, pipeline);
                                patchState(store, { pipelines });
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to create pipeline`)
                            }
                        })
                    )
                })
            )
        ),
        update: rxMethod<UpdatePipelineRxArgs>(
            pipe(
                switchMap((pipeline) => {
                    return service.update(pipeline.id, pipeline.body).pipe(
                        tapResponse({
                            next: (pipeline) => {
                                const pipelines: PipelinesMap = new Map(store.pipelines());
                                pipelines.set(pipeline.id, pipeline);
                                patchState(store, { pipelines });
                                alert.info(`Program saved`);
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to update program`);
                            }
                        })
                    )
                })
            )
        ),
        delete: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.delete(id).pipe(
                        tapResponse({
                            next: () => {
                                const pipelines: PipelinesMap = new Map(store.pipelines());
                                pipelines.delete(id);
                                patchState(store, { pipelines });
                            },
                            error: function (error: unknown): void {
                                throw new Error('Function not implemented.');
                            }
                        })
                    )
                })
            )
        ),
        refresh: rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loadingInProgress: true })),
                exhaustMap(() => {
                    return service.getAll()
                        .pipe(
                            tapResponse({
                                next: (pipelines) => {
                                    const PipelinesMap = new Map<string, Pipeline>();
                                    pipelines.forEach(pipeline => {
                                        PipelinesMap.set(pipeline.id, pipeline);
                                    });
                                    patchState(store, { pipelines: PipelinesMap, loadingInProgress: false });
                                },
                                error: () => {
                                    patchState(store, { loadingInProgress: false });
                                }
                            }),
                            switchMap(() => from([...store.pipelines().keys()])),
                            mergeMap((id) => {
                                return service.getJobs(id).pipe(
                                    tapResponse({
                                        next: (jobs) => {
                                            const newJobs = new Map(store.jobs());
                                            newJobs.set(id, new Map(jobs.map(job => [job.id, job])));
                                            patchState(store, { jobs: newJobs });
                                        },
                                        error: function (error: unknown): void {
                                            alert.error(`Failed to execute pipeline`);
                                        }
                                    })
                                )
                            })
                        )
                }),

            )
        ),
        execPipeline: rxMethod<ExecPipelineRxArgs>(
            pipe(
                switchMap((args) => {
                    return service.execPipeline(args.pipeline.id, {
                        variables: args.variables
                    }).pipe(
                        tapResponse({
                            next: () => {
                                alert.info(`Pipeline executed`);
                            },
                            error: function (error: unknown): void {
                                alert.error(`Failed to execute pipeline`);
                            }
                        })
                    )
                })
            )
        ),
        getExecutions: rxMethod<string>(
            pipe(
                switchMap((id) => {
                    return service.getExecutions(id).pipe(
                        tapResponse({
                            next: (executions) => {
                                patchState(store, { executions: executions.executions });
                            },
                            error: function (error: unknown): void {
                                throw new Error('Function not implemented.');
                            }
                        }),
                        switchMap(() => from([...Object.keys(store.executions())])),
                        filter((id) => !store.executions_data().has(id)),
                        mergeMap((id) => {
                            return service.getExecution(id).pipe(
                                tapResponse({
                                    next: (execution) => {
                                        const executions_data = new Map(store.executions_data());
                                        executions_data.set(id, execution);
                                        patchState(store, { executions_data });

                                        if (profileStore.profiles().has(execution.triggerer)) return;

                                        profileStore.getOne(execution.triggerer)
                                    },
                                    error: function (error: unknown): void {
                                        throw new Error('Function not implemented.');
                                    }
                                })
                            )
                        }),
                    )
                })
            )
        )
    })),
);