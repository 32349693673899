// import { IsMongoId, IsNotEmpty, IsOptional, IsString, IsUUID } from "class-validator";
// import { ApiProperty } from "@nestjs/swagger";

export class IDDto {
  // @IsNotEmpty()
  // @IsString()
  // @IsMongoId()
  // @ApiProperty()
  id!: string;
}
