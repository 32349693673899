import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN, type Environment } from '@codingbook/ngx-env';
import { Comment, CreateCommentDTO } from '@codingbook/shared';

@Injectable({
  providedIn: 'root',
})
export class CommentService {

  environment: Environment;
  readonly BASE_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) environment: Environment 
  ) {
    this.environment = environment;
    this.BASE_URL = `${this.environment.SOCIAL_URL}/comment`;
  }

  createPostComment(body: CreateCommentDTO, postId: string) {
    return this.http.post<Comment>(`${this.BASE_URL}/post/${postId}`, body);   
  }

  getAllFromPost(id: string) {
    return this.http.get<Comment[]>(`${this.BASE_URL}/post/${id}`);   
  }

  delete(id: string) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  like(id: string) {
    return this.http.post(`${this.BASE_URL}/${id}/like`, {});
  }

  unlike(id: string) {
    return this.http.post(`${this.BASE_URL}/${id}/unlike`, {});
  }
}
