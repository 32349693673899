import { Route } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { inject } from '@angular/core';

export const appRoutes: Route[] = [
    { 
        path: '',
        loadComponent: () => import('./feed/feed.component').then(m => m.FeedComponent),
    },
    {
        path: 'catalog',
        loadComponent: () => import('./catalog/catalog.component').then(m => m.CatalogComponent)
    },
    {
        path: 'editor',
        loadComponent: () => import('./code/editor/editor.component').then(m => m.EditorComponent),
        canMatch: [() => inject(AuthGuard).canMatch()]
    },
    {
        path: 'editprofile',
        loadComponent: () => import('./profile/edit-profile.component').then(m => m.EditProfileComponent),
        canMatch: [() => inject(AuthGuard).canMatch()]
    },
    {
        path: 'profile',
        loadComponent: () => import('./profile/profile.component').then(m => m.ProfileComponent),
        canActivate: [() => inject(AuthGuard).canMatch()]
    },
    {
        path: 'profile/:id',
        loadComponent: () => import('./profile/profile.component').then(m => m.ProfileComponent),
        canActivate: []
    },
    {
        path: 'groups',
        loadComponent: () => import('./groups/group.component').then(m => m.GroupComponent),
    },
    {
        path: 'groups/:id',
        loadComponent: () => import('./groups/group-details.component').then(m => m.GroupDetailsComponent),
        canActivate: [() => inject(AuthGuard).canMatch()]
    },
    {
        path: '**', redirectTo: '/'
    }
];
