export enum TranslationKeys {
    requests = 'requests',
    accept = 'accept',
    reject = 'reject',
    leave = 'leave',
    members = 'members',
    groups = 'groups',
    entryFile = 'entryFile',
    addVariable = 'addVariable',
    searchMessages = 'searchMessages',
    sendMessage = 'sendMessage',
    noStatus = 'noStatus',
    seeComments = 'seeComments',
    hideComments = 'hideComments',
    addComment = 'addComment',
    comment = 'comment',
    languages = 'languages',
    stack = 'stack',
    input = 'input',
    output = 'output',
    private = 'private',
    public = 'public',
    nothing = 'nothing',
    logs = 'logs',
    history = 'history',
    state = 'state',
    details = 'details',
    variables = 'variables',
    clickOnAFileToSeeItContent = 'clickOnAFileToSeeItContent',
    newPipeline = 'newPipeline',
    search = 'search',
    start = 'start',
    save = 'save',
    code = 'code',
    messages = 'messages',
    useMyPosition = 'useMyPosition',
    join = 'join',
    quantity = 'quantity',
    price = 'price',
    refresh = 'refresh',
    actions = 'actions',
    delete = 'delete',
    filter = 'filter',
    name = 'name',
    enter_name = 'enter_name',
    creation = 'creation',
    updated = 'updated',
    description = 'description',
    no_data = 'no_data',
    yes = 'yes',
    no = 'no',
    submit = 'submit',
    cancel = 'cancel',
    login = 'login',
    register = 'register',
    admin = 'admin',
    logout = 'logout',
    help = 'help',
    slogan = 'slogan',
    profile = 'profile',
    downloadApp = 'downloadApp',
    catalog = 'catalog',
    about = 'about',
    mail = 'mail',
    entername = 'entername',
    entermail = 'entermail',
    entercurrentpassword = 'entercurrentpassword',
    currentpassword = 'currentpassword',
    enternewpassword = 'enternewpassword',
    newpassword = 'newpassword',
    total = 'total',
    status = 'status',
    seller = 'seller',
    create = 'create',
    edit = 'edit',
    editProfile = 'editProfile',
    info = 'info',
    location = 'location',
    offers = 'offers',
    sort = 'sort',
    distance = 'distance',
    note = 'note',
    back = 'back',
    available = 'available',
    noCategories = 'noCategories',
    close = 'close',
    contactDetails = "contactDetails",
    address = "address",
    city = "city",
    country = "country",
    zipcode = "zipcode",
    previous = "previous",
    next = "next",
    done = "done",
    creationEnded = "creationEnded",
    preferences = "preferences",
}
