import { Environment } from "@codingbook/ngx-env";

export const environment: Environment = {
  GATEWAY_URL: "https://jobs.scrypteur.com",
  SOCIAL_URL: "https://social.scrypteur.com",
  WEBSOCKET_SOCIAL_URL: "wss://social.scrypteur.com",
  WEBSOCKET_URL: "wss://jobs.scrypteur.com/ws",
  KEYCLOAK_URL: "https://k.scrypteur.com",
  KEYCLOAK_REALM: "codingbook",
  KEYCLOAK_CLIENTID: "codingbook",
  LIVEBLOCKS_PK: "pk_dev_c1rHOGVB8xfUAkdiOZn_DwhefdJK6hZFL-h2RQNjN8Qi9Qacq67dE63vFe2Th39V"
}
