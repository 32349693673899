import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ReactiveFormsModule, FormControl } from '@angular/forms';

@Component({
  selector: 'codingbook-input-simple',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    TextFieldModule,
    MatIconModule,
  ],
  templateUrl: './input-simple.component.html',
  styleUrls: ['./input-simple.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSimpleComponent {
  value = '';

  @Input({required: true}) placeholder = '';
  @Input({required: true}) label = '';
  // @ts-ignore
  @Input({required: true}) formControlSimple: FormControl;
  @Input({required: true}) required = false;
  @Input({required: false}) type = 'text';
}
