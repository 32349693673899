import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@codingbook/ngx-env';
import { CreateProgramDTO, GetProgramsDTO, Program, Stack, UpdateProgramDTO } from '@codingbook/shared';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {

  environment: Environment;
  readonly BASE_URL: string;
  readonly BASE_URL_SOCIAL: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) environment: Environment 
  ) {
    this.environment = environment;
    this.BASE_URL = `${this.environment.GATEWAY_URL}/programs`;
    this.BASE_URL_SOCIAL = `${this.environment.SOCIAL_URL}/programs`;
  }

  create(body: CreateProgramDTO) {
    return this.http.post<Program>(`${this.BASE_URL}`, body);   
  }

  getAll() {
    return this.http.get<GetProgramsDTO>(`${this.BASE_URL}`);   
  }

  getOne(id: string) {
    return this.http.get<Program>(`${this.BASE_URL}/${id}`);
  }
  
  update(id: string, body: UpdateProgramDTO) {
    return this.http.put<Program>(`${this.BASE_URL}/${id}`, body);   
  }

  delete(id: string) {
    return this.http.delete(`${this.BASE_URL}/${id}`);   
  }

  getStacks() {
    return this.http.get<Stack[]>(`${this.BASE_URL}/stacks`);
  }

  getIOs() {
    return this.http.get<string[]>(`${this.BASE_URL}/ios`);
  }

  like(id: string) {
    return this.http.post(`${this.BASE_URL_SOCIAL}/${id}/like`, null);
  }

  unlike(id: string) {
    return this.http.post(`${this.BASE_URL_SOCIAL}/${id}/unlike`, null);
  }

  likes(id: string) {
    return this.http.get<string[]>(`${this.BASE_URL_SOCIAL}/${id}/likes`);
  }
}
