export class Job {
    id!: string;
    name!: string;
    state!: string;
    program!: string;
    input!: string;
    variables!: Map<string, string>;
    created_at!: string;
    updated_at!: string;
    duration!: number;
}
