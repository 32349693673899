import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { debounceTime, of, pipe, switchMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CodeModel } from '@ngstack/code-editor';

export type language = 'shell' | 'python' | 'rust' | 'toml' | 'yaml' | 'c' | 'json' | 'javascript' | 'typescript' | 'html' | 'css' | 'txt';
export type extension = 'sh' | 'py' | 'rs' | 'toml' | 'yaml' | 'c' | 'json' | 'js' | 'ts' | 'html' | 'css' | 'txt';

interface SelectFileRxArgs {
    id: string;
    filename: string;
}

export interface File {
  id: string;
  items?: undefined
  content: string;
}

export interface Folder {
  id: string;
  items: Map<string, File | Folder>;
  isOpen: boolean;
}

export interface EditorState {
  model: CodeModel,
  options: {
    contextmenu: boolean;
    minimap: {
        enabled: boolean;
    },

  },
  currentItemId: string;
  creationParent: string;
  currentFile: string;
  newItemName: string;
  renameSelection: string;
  renameSelectionParent: string;
  items: Folder;
  languages: Map<extension, language>;
  openedFolders: Set<string>;
}

const languages = new Map<extension, language>([
    ['sh', 'shell'],
    ['py', 'python'],
    ['rs', 'rust'],
    ['toml', 'toml'],
    ['yaml', 'yaml'],
    ['c', 'c'],
    ['json', 'json'],
    ['js', 'javascript'],
    ['ts', 'typescript'],
    ['html', 'html'],
    ['css', 'css'],
    ['txt', 'txt']
])

export function findItem(id: string, items: Folder):  File | Folder | null {
    let file: File | Folder | null = null;
    if (items.id === id) {
        return items;
    }
    
    for (const item of items.items.values()) {
        if (item.id === id) {
            file = item as File;
            return file;
        }

        if (item.items) {
            const subFile = findItem(id, item);
            if (subFile) {
                return subFile;
            }
        }
    }

    return file;
}

// model: CodeModel = {
//     language: 'json',
//     uri: 'main.json',
//     value: '',
//   };

//   options = {
//     contextmenu: true,
//     minimap: {
//       enabled: true
//     }
//   };

const initialState: EditorState = {
    model: {
        language: 'shell',
        uri: 'main.sh',
        value: '',
    },
    options: {
        contextmenu: true,
        minimap: {
          enabled: true
        }
    },
    openedFolders: new Set<string>(),
    currentItemId: "",
    currentFile: "",
    creationParent: "",
    renameSelection: "",
    renameSelectionParent: "",
    newItemName: "",
    languages,
    items: {
        id: uuidv4(),
        isOpen: true,
        items: new Map<string, File | Folder>([]),
        // items: new Map<string, File | Folder>([
        //   ['app.component.ts', {
        //     id: uuidv4(),
        //     content: `import { Component } from '@angular/core';`
        //   }],
        //   ['app.component.html', {
        //     id: uuidv4(),
        //     content: `<h1>Hello, World!</h1>`
        //   }],
        //   ['folder1', {
        //     id: uuidv4(),
        //     isOpen: true,
        //     items: new Map<string, File | Folder>([
        //       ['file1.rs', {
        //         id: uuidv4(),
        //         content: `fn main() {}`
        //       }],
        //       ['folder2', {
        //         id: uuidv4(),
        //         isOpen: false,
        //         items: new Map<string, File | Folder>([
        //           ['file2.rs', {
        //             id: uuidv4(),
        //             content: `fn main() {}`
        //           }],
        //         ])
        //       }]
        //     ])
        //   }]
        // ])
        }
}

export const EditorStore = signalStore(
    withState<EditorState>(initialState),
    withMethods((store) => ({
        reset: () => {
            patchState(store, initialState);
        },
        // updateModel: (model: CodeModel) => {
        //     patchState(store, { model });
        // },
        selectFile: rxMethod<SelectFileRxArgs>(
            pipe(
                debounceTime(100),
                switchMap((args) => {
                    const item = findItem(args.id, store.items()) as File;
                    if (!item) {
                        throw new Error('Item not found');
                    };
                    
        //             const model: CodeModel = {
        //                 ...store.model(),
        //                 value: item.content
        //             }
        //             console.log('selectFile', args.id, args.filename, item.content)

                    patchState(store, { currentItemId: args.id, currentFile: args.filename});
        //             patchState(store, { currentItemId: args.id, currentFile: args.filename, model});
                    return of(null);
                }
                
            )
        )),
        selectFolder: (item: string) => {
            const openedFolders = new Set<string>(store.openedFolders());
            if (openedFolders.has(item)) {
                openedFolders.delete(item);
            } else {
                openedFolders.add(item);
            }
            patchState(store, { openedFolders });
        },
        openFolder(item: string) {
            const openedFolders = new Set<string>(store.openedFolders());
            openedFolders.add(item);
            patchState(store, { openedFolders, creationParent: item });
        },
        createItemFromUpload: (name: string, content: string = "") => {
            const parent = store.items()

            if (parent.items.has(name)) {
                if (confirm('Item already exists, do you want to overwrite it?')) {
                    parent.items.delete(name);
                }
            }

            const id = uuidv4();

            parent.items.set(name, {
                id,
                content
            });
        },
        createItem: (name: string, type: "file" | "folder") => {
            const items = store.items();
            const parent = findItem(store.creationParent(), items);
            if (!parent) {
                throw new Error('Item not found');
            };

            if (!parent.items) {
                throw new Error('Item is not a folder');
            }

            if (parent.items.has(name)) {
                if (confirm('Item already exists, do you want to overwrite it?')) {
                    parent.items.delete(name);
                }
            }

            const id = uuidv4();

            if (type === 'folder') {
                parent.items.set(name, {
                    id,
                    items: new Map<string, File | Folder>(),
                    isOpen: true
                });
            } else {
                parent.items.set(name, {
                    id,
                    content: ''
                });
            }

            const model: CodeModel = {
                ...store.model(),
                value: ''
            }

            patchState(store, {
                currentItemId: id,
                currentFile: name,
                model,
                creationParent: '',
                newItemName: '',
                items
            });
        },
        endItemCreation: () => {
            patchState(store, { creationParent: "", newItemName: ""});
        },
        closeFile: () => {
            const model: CodeModel = {
                ...store.model(),
                value: ''
            }

            patchState(store, { currentItemId: "", currentFile: "", model});
        },
        deleteItem: (parentId: string, filename: string) => {
            const items = store.items();
            const parent = findItem(parentId, items) as Folder;
            if (!parent) {
                throw new Error('Item not found');
            };

            if (!parent.items) {
                throw new Error('Item is not a folder');
            }
            
            console.log(parent.items, filename)
            parent.items.delete(filename);

            if (store.currentFile() == filename) {
                patchState(store, { currentFile: '' });
            }

            patchState(store, { items });
        },
        renameItem: (oldItemName: string) => {
            const items = store.items();
            const parent = findItem(store.renameSelectionParent(), items) as Folder;
            if (!parent) {
                throw new Error('Item not found');
            };

            if (!parent.items) {
                throw new Error('Item is not a folder');
            }
            
            const item = findItem(store.renameSelection(), parent); // or items ?
            if (!item) {
                throw new Error('Item not found');
            }

            parent.items.set(store.newItemName(), {
                ...item
            });

            parent.items.delete(oldItemName);

            if (store.currentFile() == oldItemName) {
                patchState(store, { currentFile: store.newItemName() });
            }

            patchState(store, {
                items,
                renameSelection: "",
                renameSelectionParent: "",
            });
        },
        newItemNameChange: (name: string) => {
            patchState(store, { newItemName: name });
        },
        renameSelectionChange: (parent: string, id: string) => {
            patchState(store, {
                renameSelectionParent: parent,
                renameSelection: id
            });
        },
        codeChange: (code: string) => {
            if (!store.currentItemId()) {
                return;
            }

            const item = findItem(store.currentItemId(), store.items()) as File;
            if (!item) {
                throw new Error('Item not found');
            };
            console.log('codeChange', store.currentItemId(), code)
            item.content = code;
        },
        setItems: (items: Folder) => {
            patchState(store, { items });
        }
    }))
);