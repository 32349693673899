import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@codingbook/ngx-env';
import { CreatePipelineDTO, ExecPipelineDTO, GetPipelineExecutionDTO, GetPipelineExecutionsDTO, Job, Pipeline, UpdatePipelineDTO } from '@codingbook/shared';

@Injectable({
  providedIn: 'root',
})
export class PipelineService {

  environment: Environment;
  readonly BASE_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) environment: Environment 
  ) {
    this.environment = environment;
    this.BASE_URL = `${this.environment.GATEWAY_URL}/pipelines`;
  }

  create(body: CreatePipelineDTO) {
    return this.http.post<Pipeline>(`${this.BASE_URL}`, body);   
  }

  getAll() {
    return this.http.get<Pipeline[]>(`${this.BASE_URL}`);   
  }

  getOne(id: string) {
    return this.http.get<Pipeline>(`${this.BASE_URL}/${id}`);
  }
  
  update(id: string, body: UpdatePipelineDTO) {
    return this.http.put<Pipeline>(`${this.BASE_URL}/${id}`, body);   
  }

  delete(id: string) {
    return this.http.delete(`${this.BASE_URL}/${id}`);   
  }

  execPipeline(id: string, body: ExecPipelineDTO) {
    return this.http.post(`${this.BASE_URL}/${id}/exec`, {
      variables: Object.fromEntries(body.variables)
    });
  }

  getJobs(id: string) {
    return this.http.get<Job[]>(`${this.BASE_URL}/${id}/jobs`);
  }

  getExecutions(id: string) {
    return this.http.get<GetPipelineExecutionsDTO>(`${this.BASE_URL}/${id}/executions`);
  }

  getExecution(id: string) {
    return this.http.get<GetPipelineExecutionDTO>(`${this.BASE_URL}/executions/${id}`);
  }
}
