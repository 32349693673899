import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { signalStore, withState, withMethods, withComputed, patchState } from '@ngrx/signals';
import { pipe, tap } from 'rxjs';
import { inject } from '@angular/core';
import { AlertService } from '../alert.service';
import { ENVIRONMENT_TOKEN } from '@codingbook/ngx-env';
import { JobStore } from './job.store';

export interface LogsState {
    logs: string[];
    ws: WebSocket | null;
    ready: boolean;
    available: boolean;
    failed: boolean;
    status: string;
    loadingInProgress: boolean;
    end: boolean;
}

const logsInitialState: LogsState = {
    logs: [],
    ws: null,
    ready: false,
    available: false,
    failed: false,
    status: 'Nothing yet',
    loadingInProgress: false,
    end: false
};

export const LogsStore = signalStore(
    withState<LogsState>(logsInitialState),
    withMethods((store, environment = inject(ENVIRONMENT_TOKEN), alert = inject(AlertService), jobStore = inject(JobStore)) => ({
        connect: rxMethod<void>(
            pipe(
                tap(() => {
                    if (store.ws()) return;
                    const ws = new WebSocket(environment.WEBSOCKET_URL);
                    ws.onmessage = (event) => {
                        const message = event.data as string;
                        if (message) {
                            if (message === 'Running') {
                                patchState(store, {
                                    available: true,
                                    status: message,
                                    loadingInProgress: false
                                });
                            } else if (message === 'Succeeded') {
                                patchState(store, {
                                    available: true,
                                    status: message,
                                    loadingInProgress: false,
                                    end: true
                                });
                            } else if (message === 'Failed') {
                                patchState(store, {
                                    available: true,
                                    failed: true,
                                    status: message,
                                    loadingInProgress: false,
                                    end: true
                                });
                                alert.error('Logs failed');
                            } else if (message === 'Pending' && !store.available()) {
                                patchState(store, {
                                    available: false,
                                    status: message,
                                    loadingInProgress: true
                                });
                            } else if (message === 'Not found') {
                                patchState(store, {
                                    available: false,
                                    failed: true,
                                    status: message,
                                    loadingInProgress: false
                                });
                                alert.error('Logs not found');
                            } else if (message == '/close') {
                                const jobId = jobStore.lastJob()?.id || '';
                                if (!jobId) return;
                                store.ws()?.send(`/status ${jobId}`);

                                setTimeout(() => {
                                    store.ws()?.close();
                                }, 3000);
                                patchState(store, {
                                    ...logsInitialState,
                                    logs: store.logs(),
                                    loadingInProgress: false,
                                    status: store.status()
                                });
                            } else {
                                if (message.startsWith('/log')) {
                                    patchState(store, {
                                        logs: [...store.logs(), message.split(' ').slice(1).join(' ')]
                                    });
                                }
                            }
                        }
                    }
                    ws.onclose = (e) => {
                        console.log('Connection closed');
                        patchState(store, {
                            ready: false,
                            ws: null,
                        });
                    }
                    ws.onerror = (error) => {
                        console.error('WebSocket error:', error);
                        alert.error('Logs error');
                    },
                    ws.onopen = () => {
                        console.log('Connection open');
                        patchState(store, {
                            ready: true,
                            end: false
                        });
                    }
                    patchState(store, {
                        ws
                        // ws: new WebSocket(`ws://${environment.apiHost}/ws`)
                    });
                })
            )
        ),
        disconnect: rxMethod<void>(
            pipe(
                tap(() => {
                    const ws = store.ws();
                    if (!ws) throw new Error('WebSocket not found');

                    patchState(store, logsInitialState);

                    ws.close();
                })
            )
        ),
        setLoading: rxMethod<boolean>(
            pipe(
                tap((x) => {
                    patchState(store, {
                        loadingInProgress: x
                    });
                })
            )
        ),
        send: rxMethod<string>(
            pipe(
                //TODO  : wait for ready state before sending
                tap((message) => {
                    if (!store.ready || !store.ws()) {
                        alert.error('Logs not ready');
                        return;
                    }

                    store.ws()?.send(message);
                })
            )
        ),
        clearLogs: rxMethod<void>(
            pipe(
                tap(() => {
                    patchState(store, {
                        logs: [],
                        available: false,
                        failed: false
                    });
                })
            )
        ),
    }))
);