import { Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { TchatComponent } from './tchat/tchat.component';
import { MessageStore } from './stores/message.store';
import { CommonModule } from '@angular/common';
import { AuthStore } from './stores/auth.store';
import { ProfileStore } from './stores/profile.store';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatButtonModule,
    TchatComponent
  ],
  selector: 'codingbook-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'codingbook';

  constructor(translate: TranslateService) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
    translate.use('en');

    toObservable(this.authStore.profile).subscribe(profile => {
      if (profile?.id) {
        this.profileStore.getMyProfile(profile.id);
      }
    });
  }

  messageStore = inject(MessageStore);
  authStore = inject(AuthStore);
  profileStore = inject(ProfileStore);

  ngOnInit(): void {
    setTimeout(() => {
      this.messageStore.connect()
    }, 2000);
  }
}

